import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { default as BreadCrumbsBase } from '@jetshop/ui/Breadcrumbs';

const StyledBreadCrumbs = styled(BreadCrumbsBase)`
   margin-bottom: 20px;
   li,
   a {
      font-size: var(--text-size-small);
      color: var(--text-color);
      text-transform: uppercase;
      text-decoration: unset;
      letter-spacing: 0.06em;

      &:last-child:after {
         content: '';
      }
   }
`;

const BreadCrumbs = ({ ...props }) => {
   return <StyledBreadCrumbs {...props} />;
};

export default BreadCrumbs;
