import CategoryHeader from '../CategoryPage/CategoryHeader';
import ImageBlock from './ImageBlock/ImageBlock';
import ImageBlockGrid from './ImageBlock/ImageBlockGrid';
import LinkCards, { LinkCard } from './LinkCards';

/**
 * Used by <ContentRenderer /> component mapping
 *
 * @usage
 *  {
 * ...ContentElements
 * }
 */

export const ContentElements = {
   LINKCARDS: LinkCards,
   LINKCARD: LinkCard,
   'CATEGORY HEADER': CategoryHeader,
   'IMAGE BLOCK GRID': ImageBlockGrid,
   IMAGEBLOCK: ImageBlock,
};

export default ContentElements;
