import React from 'react';

import { useProductList } from '@jetshop/core/hooks/ProductList';
import { css, cx } from 'linaria';

import { ReactComponent as HeartFilledSVG } from '../../svg/Heart-filled.svg';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';

const favourite = css`
   button {
      background: transparent;
      svg {
         height: 22px;
         width: 22px;
         &.active {
            fill: var(--text-color);
         }
      }
   }
`;

export function Favourite({ product, variant, className, ...rest }) {
   const articleNumber = product.articleNumber;

   const selectedVariation = product.variant || variant;
   const variantArticleNumber = selectedVariation?.articleNumber;

   const { toggle, inList } = useProductList();

   function toggleWithoutBubble(e) {
      e.preventDefault();
      toggle(articleNumber, {
         variantArticleNumber,
         productName: product.name,
      });
   }

   const active = inList(articleNumber, {
      variantArticleNumber,
   });

   return (
      <div className={cx(favourite, className)} {...rest}>
         <button onClick={toggleWithoutBubble} aria-label="Add to favourites">
            {active ? <HeartFilledSVG /> : <HeartSVG />}
         </button>
      </div>
   );
}
