import React from 'react';

import Image from '@jetshop/ui/Image/Image';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import BreadCrumbs from '../ui/BreadCrumbs.js';

export const CategoryHeaderWrapper = styled('div')`
   background-color: ${theme.colors.background};
   display: flex;
   align-items: baseline;
   justify-content: center;
   text-align: center;
   flex-direction: column;
   ${theme.below.xl} {
      max-width: 100%;
      width: 100%;
      padding: 0px;
   }
`;

const CategoryImageWrapper = styled('div')`
   height: 500px;
   width: 100%;
   margin-bottom: 15px;
   ::after {
      display: block;
      position: relative;
      width: 100%;
      content: '';
      margin-top: -320px;
      height: 320px;
   }
   ${theme.below.md} {
      height: 415px;
      margin-bottom: 15px;
      ::after {
         margin-top: -217px;
         height: 415px;
      }
   }
`;

export const NoImageWrapper = styled('div')`
   padding: 10px;
   width: 100%;
`;

const CategoryName = styled('h1')`
   line-height: normal;
   font-size: var(--h1);
`;

const CategoryContent = styled('div')`
   margin: 30px;
   max-width: 540px;
   font-size: var(--h4);
   ${theme.below.md} {
      max-width: unset;
   }
`;

const headerImageStyles = css`
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;

   position: relative;
   &:after {
      content: '';
      background-color: black;
      opacity: 0.1;
      mix-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
   }
`;

export const Inner = styled(MaxWidth)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
`;

const Content = ({ category, content }) => (
   <Inner>
      <CategoryName data-testid="page-header">{category.name}</CategoryName>
      <CategoryContent
         data-testid="category-description"
         dangerouslySetInnerHTML={{
            __html: content?.value || category.content,
         }}
      />
   </Inner>
);

const CategoryHeader = ({
   category,
   parents,
   imageSrc,
   breadcrumbs = { value: true },
   content,
}) => {
   const { value } = breadcrumbs;
   const breadcrumbProps = {
      breadcrumbText: value ? category.breadcrumbText : '',
      parents,
   };
   const isImageBackground =
      imageSrc || (category.images && category.images.length > 0);
   return (
      <CategoryHeaderWrapper>
         {isImageBackground ? (
            <CategoryImageWrapper>
               <Image
                  src={imageSrc?.value || category.images}
                  fillAvailableSpace
                  cover
                  quality={80}
                  className={headerImageStyles}
                  critical={true}
               >
                  <Content category={category} content={content} />
               </Image>
            </CategoryImageWrapper>
         ) : (
            <NoImageWrapper>
               <Content category={category} content={content} />
            </NoImageWrapper>
         )}
         <MaxWidth>
            <BreadCrumbs {...breadcrumbProps} />
         </MaxWidth>
      </CategoryHeaderWrapper>
   );
};

export default CategoryHeader;
