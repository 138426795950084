import React from 'react';
import { useQuery } from 'react-apollo';

import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import { styled } from 'linaria/react';

import ContentElements from '../ContentElements';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import { theme } from '../Theme';
import StartPageCampaign from './Content/StartPageCampaign';
import StartPageCategories from './Content/StartPageCategories';
import { StartPageCategoryItem } from './Content/StartPageCategories';
import StartPageHero from './Content/StartPageHero';
import StartPageHTMLContent from './Content/StartPageHTMLContent';
import StartPageProductGrid from './Content/StartPageProductGrid';

import startPageQuery from './StartPageQuery.gql';

const startPageComponents = {
   HERO: StartPageHero,
   HTML: StartPageHTMLContent,
   CATEGORIES: StartPageCategories,
   CAMPAIGN: StartPageCampaign,
   'PRODUCT GRID': StartPageProductGrid,
   CATEGORYITEM: StartPageCategoryItem,
   ...ContentElements,
};

const StartPageWrapper = styled('div')`
   width: 100%;
   display: flex;
   flex-direction: column;
`;

export const MainSectionWrapper = styled(MaxWidth)`
   width: 100%;
   align-self: center;
   padding-left: 1.5rem;
   padding-right: 1.5rem;
   ${theme.below.sm} {
      padding-left: 0px;
      padding-right: 0px;
   }
`;
const StartPage = ({ startPageId }) => {
   const result = useQuery(startPageQuery, {
      variables: { startPageId: startPageId },
   });
   const { loading } = result;
   if (loading || !result?.data) return <LoadingPage />;

   return (
      <StartPageWrapper>
         <StartPageRenderer
            result={result}
            startPageComponents={startPageComponents}
            LoadingPage={LoadingPage}
         />
      </StartPageWrapper>
   );
};

export default StartPage;
